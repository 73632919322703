.inputbox {
    display: flex;
}

.inputbox input {
    width: 380px;
    padding: 10px;
    border: 1px solid #5c5b5b;
    border-radius: 5px;
    outline: none;
    color: #fff;
    font-size: 16px;
    transition: 0.5s;
    background: transparent;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 40rem #232B32 inset;
    caret-color: #fff;
}

.inputbox span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    pointer-events: none;
    font-size: 16px;
    color: #D0D9F6;
    text-transform: uppercase;
    transition: 0.5s;
}

.inputbox input:valid~span,
.inputbox input:focus~span {
    transform: translateX(12px) translateY(-25px);
    font-size: 0.9em;
    padding: 0 10px;
    background: #3AA641;
    color: #D0D9F6;
}

.inputbox input:valid,
.inputbox input:focus {
    border: 1px solid #3AA641;
}

.checkbox_label label {
    color: #3AA641;
    text-decoration: #3AA641;
}
.username_email,
.passwords,
.first_last,
.phone_year {
    display: flex;
}
.email_margin, 
.password_margin, 
.lastname_margin, 
.year_margin {
    padding: 0 0 0 15px;
}
.register_button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_register {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0 0;
}
.login_register span {
    font-family: "Helvetica Neue", Roboto, Arial, Sans-Serif, serif;
    cursor: pointer;
    color: #D0D9F6;
}
.login_register span:hover {
    color: #3AA641;
}
.password_error {
    display: flex;
    flex-direction: column;
    width: 400px;
}
.password-strength-error {
    color: rgb(241, 29, 29);
}
.password-match-error {
    color: rgb(241, 29, 29);
}


.popup {
    display: flex;
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-popup {
    cursor: pointer;
    align-self: flex-end;
    font-size: 20px;
    font-weight: bold;
  }

  




@media (max-width: 500px) {
    .inputbox {
        display: flex;
        flex-direction: column;
    }
    .username_email,
    .passwords,
    .first_last,
    .phone_year {
        display: flex;
        flex-direction: column;
    }
    .email_margin, 
    .password_margin, 
    .lastname_margin, 
    .year_margin {
        padding: 0;
    }
    .monthly_text {
        display: flex;
        margin-left: auto;
    }
    .popup {
        display: flex;
        z-index: 1;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 10px;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
      
      .popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .close-popup {
        cursor: pointer;
        align-self: flex-end;
        font-size: 20px;
        font-weight: bold;
      }
      
}


