.details-section {
    /* display: flex; */
    width: 100%;
    
}
.user_detail_heading,
.user_detail_image {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_detail_profile_img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.user-info-container {
    width: 800px;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.header {
    background-color: #f2f2f2;
    padding: 10px;
    /* text-align: center; */
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: 600;
    color: #293239;
}

.info-row {
    display: flex;
    /* flex-wrap: wrap; */
    border-bottom: 1px solid #ccc;
}

.info-column {
    flex: 1 1 50%; /* Two columns, each taking 50% width */
    padding: 10px;
    text-align: left;
}

/* Adjust the padding for better alignment if needed */
.info-column:first-child {
    padding-right: 10px;
}



.uploaded-documents-container {
    width: 800px;
    margin: 0 0 50px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.uploaded-files-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    /* display: flex; */
}

.uploaded-files-list li {
    /* flex: 1 1 50%; */
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.uploaded-files-list li:last-child {
    border-bottom: none;
    margin-bottom: 0;
}



