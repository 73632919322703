.child_div_heading {
    font-size: 20px;
    color: #F2F4F9;
    margin-bottom: 10px;
}


.popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.popup-message p {
    margin: 0;
}

.popup-message button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}
