@media (min-width: 1024px) {

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    -ms-overflow-style: none;

  }

  body::-webkit-scrollbar {
    width: 0px;
  }

  .Homepage_master_div {
    position: relative;
    width: 100%;
    background-color: #F2F4F8;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
  }

  .homepage_background_picture {
    display: flex;
    position: relative;
    width: 100%;
    /* left: -20%; */
    overflow: hidden;
    margin-bottom: 40px;
  }

  .homepage_background_picture img {
    height: 850px;
    width: 100%;
    object-fit: cover;
  }

  .homepage_background_picture span {
    font-family: "Rubik", sans-serif;
    display: flex;
    position: absolute;
    top: 22%;
    right: 0;
    width: 30%;
    height: 30%;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    padding: 10px 30px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    z-index: 1;
    line-height: 1.25;
    animation: slideIn 2s forwards;
  }

  @keyframes slideIn {
    0% {
      right: -30%;
    }

    100% {
      right: 0;
    }
  }

  .check_our_service_button {
    display: flex;
    position: absolute;
    color: black;
    top: 17%;
    left: 40%;
    width: auto;
    padding: 0 20px 0 20px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    background-color: #3AA641;
    cursor: pointer;
    opacity: 80%;

  }

  .forward_icon {
    font-size: 40px;
    margin: 5px 0 0 10px;
  }

  .check_our_service_button:hover {
    opacity: 100%;
  }

  .service_offered {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 10px 40px;
    width: 100%;
  }

  .service_offered span {
    display: inline-block;
    /* position: absolute; */
    color: #3AA641;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 10%;
    margin-left: 50px;
  }

  .service_offered h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    margin-left: -100px;
    width: 50%;
    text-transform: capitalize;
  }


  @keyframes flip {
    0% {
      transform: perspective(100px) rotateY(90deg);
    }

    100% {
      transform: perspective(100px) rotateY(0);
    }
  }

  .animate-flip {
    animation: flip 1s ease-in-out;
  }

  .service_offered_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;

  }

  .cards_div {
    display: flex;
    width: 100%;
    height: 400px;
    gap: 30px;
    align-items: center;
    justify-content: center;
    perspective: 500px;
    top: 0;
    margin-bottom: 40px;
  }

  .single_card {
    width: 25%;
    height: 300px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }

  .single_card:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .card_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    color: #3AA641;
  }

  .card_image img {
    width: 100%;
    height: 95%;
  }

  .card_text {
    display: flex;
    /* position: absolute; */
    /* padding: 0 0 0 50px; */
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #232B32;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_container {
    display: flex;
    margin-bottom: 40px;
  }

  .brand_strategy_img {
    width: 100%;
    height: 70vh;
    background-image: url('../../assets/image/brand_strategy.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
  }

  .brand_strategy_span {
    display: flex;
    position: absolute;
    width: 75%;
    height: 70vh;
    right: 0;
    background-color: #EFFDFF;
    color: #000;
    flex-direction: column;
  }

  .brand_strategy_title {
    display: flex;
    font-size: 40px;
    font-weight: 400;
    margin-right: 150px;
    line-height: 1.4;
    height: 150px;
    padding: 60px 80px 0 120px;
    text-transform: capitalize;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_title::before {
    position: absolute;
    content: "";
    background-color: #000;
    width: 60px;
    height: 2px;
    left: 40px;
    margin-top: 25px;
  }

  /* .brand_strategy_title:hover::before {
  background-color: #3AA641;
} */
  .brand_strategy_paragrpah {
    display: flex;
    font-size: 18px;
    font-family: "Jost", sans-serif;
    color: #545454;
    line-height: 1.4;
    margin-bottom: 20px;
    padding: 0 200px 0 120px;
  }

  .background {
    background-color: #fff;
  }

  .choose_us {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 10px 40px;
    width: 100%;
  }

  .choose_us span {
    display: inline-block;
    color: #3AA641;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 15%;
    margin-left: -20px;
    margin-right: 40px;
    margin-top: 70px;
  }

  .choose_us h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    margin-left: -130px;
    width: 50%;
    text-transform: capitalize;
  }

  .choose_us_paragraph {
    display: flex;
    width: 40%;
    margin-left: 140px;
    font-size: 18px;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #545454;
    letter-spacing: 0.5px;
    word-spacing: 2px;
    margin-bottom: 40px;
  }

  .trust_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25vh;
    background-color: #F2F4F8;
  }

  .trust_logo img {
    margin-left: 60px;
    width: 220px;
    filter: grayscale(100%);
    opacity: 40%;
  }

}







@media (min-width: 501px) and (max-width: 1023px) {
  /* Your responsive styles for tablets and small laptops here */
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    -ms-overflow-style: none;

  }

  body::-webkit-scrollbar {
    width: 0px;
  }

  .Homepage_master_div {
    position: relative;
    width: 100%;
    background-color: #F2F4F8;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
  }

  .static-nav-bar {
    display: none;
  }

  .homepage_background_picture {
    display: flex;
    position: relative;
    width: 100%;
    /* left: -50px; */
    overflow: hidden;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  .homepage_background_picture img {
    height: 480px;
    width: 100%;
    object-fit: cover;
  }


  .homepage_background_picture span {
    font-family: "Rubik", sans-serif;
    display: flex;
    position: absolute;
    top: 40%;
    right: 0;
    width: 35%;
    height: 30%;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    padding: 10px 30px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    line-height: 1.25;
    animation: slideIn 2s forwards;
  }

  @keyframes slideIn {
    0% {
      right: -30%;
    }

    100% {
      right: 0;
    }
  }
  .check_service_master {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .check_our_service_button {
    display: flex;
    position: absolute;
    color: black;
    top: 11%;
    width: auto;
    padding: 0 15px 0 15px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    background-color: #3AA641;
    cursor: pointer;
    opacity: 80%;
  }

  .forward_icon {
    font-size: 30px;
    margin: 5px 0 5px 8px;
  }

  .check_our_service_button:hover {
    opacity: 100%;
  }

  .service_background {
    width: 100%;
    background-color: #F2F4F8;
    padding: 0 0 5px 0;
  }

  .service_offered {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 30px 60px;
    width: 100%;
    margin-bottom: 20px;
  }

  .service_offered span {
    color: #3AA641;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 1%;
    margin-top: 100px;
  }

  .service_offered h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    width: 40%;
    text-transform: capitalize;
    padding: 0 0 0 20px;
  }


  .cards_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 30px;
    align-items: center;
    justify-content: center;
    perspective: 500px;
    top: 0;
    margin-bottom: 40px;
  }

  .single_card {
    width: 80%;
    height: 300px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }

  .single_card:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .card_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    color: #3AA641;
  }

  .card_image img {
    width: 100%;
    height: 95%;
  }

  .card_text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #232B32;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_container {
    display: flex;
    margin-bottom: 40px;
    height: auto;
  }

  .brand_strategy_img {
    width: 100%;
    height: 50vh;
    background-image: url('../../assets/image/brand_strategy.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
  }

  .brand_strategy_span {
    display: flex;
    position: absolute;
    width: 95%;
    height: auto;
    right: 0;
    background-color: #EFFDFF;
    color: #000;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .brand_strategy_title {
    display: flex;
    font-size: 26px;
    font-weight: 380;
    line-height: 1.4;
    padding: 60px 5px 0 70px;
    text-transform: capitalize;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_title::before {
    position: absolute;
    content: "";
    background-color: #000;
    width: 40px;
    height: 2px;
    left: 15px;
    margin-top: 20px;
  }

  .brand_strategy_paragrpah {
    display: flex;
    font-size: 18px;
    font-family: "Jost", sans-serif;
    color: #545454;
    line-height: 1.4;
    margin-bottom: 20px;
    padding: 10px 20px 10px 70px;
  }

  .background {
    background-color: #fff;
  }

  .choose_us {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 10px 0;
    width: 100%;
    height: auto;
  }

  .choose_us span {
    color: #3AA641;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 30%;
    margin-left: -20px;
    margin-top: 25px;
  }

  .choose_us h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    width: 30%;
    margin-left: -130px;
    text-transform: capitalize;
  }

  .choose_us_paragraph {
    display: flex;
    width: 80%;
    padding: 0 0 0 90px;
    font-size: 18px;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #545454;
    letter-spacing: 0.5px;
    word-spacing: 2px;
    margin-bottom: 40px;
  }

  .trust_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F2F4F8;
  }

  .trust_logo_grid_container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 20px 10px;
    width: 250px;
  }

  .trust_logo_grid_container img {
    width: 50%;
    filter: grayscale(100%);
    opacity: 40%;
  }
}







@media (max-width: 500px) {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .static-nav-bar {
    display: none;
  }

  .homepage_background_picture {
    display: flex;
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
  }

  .homepage_background_picture img {
    height: 450px;
    width: 100%;
    object-fit: cover;
  }

  .homepage_background_picture span {
    font-family: "Rubik", sans-serif;
    display: flex;
    position: absolute;
    top: 50%;
    right: 0;
    width: 35%;
    height: 25%;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    padding: 10px 30px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    line-height: 1.25;
    animation: slideIn 2s forwards;
  }

  @keyframes slideIn {
    0% {
      right: -30%;
    }

    100% {
      right: 0;
    }
  }
  .check_service_master {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check_our_service_button {
    display: flex;
    position: absolute;
    color: black;
    top: 60%;
    width: auto;
    padding: 0 15px 0 15px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    height: 40px;
    background-color: #3AA641;
    cursor: pointer;
    opacity: 80%;
  }

  .forward_icon {
    font-size: 30px;
    margin: 5px 0 5px 10px;
  }

  .check_our_service_button:hover {
    opacity: 100%;
  }

  .service_background {
    width: 100%;
    background-color: #F2F4F8;
    padding: 0 0 5px 0;
  }

  .service_offered {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 30px 60px;
    width: 100%;
    margin-bottom: 10px;
  }

  .service_offered span {
    color: #3AA641;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 1%;
    margin-top: 100px;
  }

  .service_offered h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    width: 50%;
    text-transform: capitalize;
    padding: 5px 0 0 20px;
  }

  .cards_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 30px;
    align-items: center;
    justify-content: center;
    perspective: 500px;
    top: 0;
    margin-bottom: 40px;
  }

  .single_card {
    width: 80%;
    height: 300px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }

  .single_card:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .card_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    color: #3AA641;
  }

  .card_image img {
    width: 100%;
    height: 95%;
  }

  .card_text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #232B32;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_container {
    display: flex;
    margin-bottom: 40px;
    height: auto;
  }

  .brand_strategy_img {
    width: 100%;
    height: 80vh;
    background-image: url('../../assets/image/brand_strategy.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
  }

  .brand_strategy_span {
    display: flex;
    position: absolute;
    width: 95%;
    height: auto;
    right: 0;
    background-color: #EFFDFF;
    color: #000;
    flex-direction: column;
  }

  .brand_strategy_title {
    display: flex;
    font-size: 26px;
    font-weight: 380;
    line-height: 1.4;
    padding: 60px 5px 0 70px;
    text-transform: capitalize;
    font-family: "Jost", sans-serif;
  }

  .brand_strategy_title::before {
    position: absolute;
    content: "";
    background-color: #000;
    width: 40px;
    height: 2px;
    left: 15px;
    margin-top: 20px;
  }

  .brand_strategy_paragrpah {
    display: flex;
    font-size: 18px;
    font-family: "Jost", sans-serif;
    color: #545454;
    line-height: 1.4;
    margin-bottom: 20px;
    padding: 10px 20px 10px 70px;
  }

  .background {
    background-color: #fff;
  }

  .choose_us {
    display: flex;
    box-sizing: border-box;
    padding: 0 0 10px 0;
    width: 100%;
    height: auto;
  }

  .choose_us span {
    display: inline-block;
    color: #3AA641;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    transform: rotate(-90deg);
    width: 55%;
    margin-top: 30px;
    margin-left: -70px;

  }

  .choose_us h2 {
    display: flex;
    font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 12px;
    margin-left: -125px;
    width: 50%;
    text-transform: capitalize;
  }

  .choose_us_paragraph {
    display: flex;
    width: 70%;
    padding: 0 0 0 80px;
    font-size: 16px;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #545454;
    letter-spacing: 0.5px;
    word-spacing: 2px;
    margin-bottom: 40px;
  }

  .trust_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F2F4F8;
  }

  .trust_logo_grid_container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 20px 10px;
    width: 250px;
  }

  .trust_logo_grid_container img {
    width: 50%;
    filter: grayscale(100%);
    opacity: 40%;
  }
}