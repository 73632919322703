@media (min-width: 1024px) {

    #product_background_picture {
        display: flex;
        position: relative;
        /* overflow: hidden; */
    }

    #product_background_picture img {
        height: 480px;
        width: 100%;
        object-fit: cover;
    }

    #product_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        left: 0;
        width: 45%;
        height: 20%;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .product_image_heading_master {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product_image_heading {
        display: flex;
        position: absolute;
        color: black;
        top: 18%;
        width: 250px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 36px;
        font-weight: 500;
        height: 60px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 80%;
        border-radius: 5px;

    }
    .product_image_heading:hover {
        opacity: 100%;
    }

    .product_service_background {
        display: flex;
        width: 100%;
        background-color: #F2F4F8;
        padding: 0 0 5px 0;
        margin-bottom: 40px;
    }

    .product_service_offered {
        display: flex;
        /* box-sizing: border-box; */
        /* padding: 50px 0 10px 40px; */
        margin-left: 100px;
        width: 100%;
    }

    .product_service_offered span {
        /* display: inline-block; */
        color: #3AA641;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        transform: rotate(-90deg);
        width: 1%;
        margin-top: 120px;
    }

    .product_service_offered h2 {
        display: flex;
        font-family: "Jost", sans-serif;
        font-size: 40px;
        font-weight: 400;
        line-height: 1.2;
        width: 30%;
        text-transform: capitalize;
        /* margin-top: 50px; */
        padding: 20px 0 0 0;
    }

    .products_master_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .products_first_row {
        display: flex;
        width: 100%;
    }
    .image-container {
        position: relative;
    }
    .image-container img {
        width: 100%;
        height: 450px;
        opacity: 1;
        transition: opacity 0.5s;
    }
    .image-container:hover img {
        opacity: 0.9;
    }

    .purchase_order_hover,
    .term_funding_hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        opacity: 0;
        transition: background-color 0.4s, opacity 0.4s;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .purchase_order_hover button,
    .purchase_order_hover h2,
    .purchase_order_hover p {
        display: none;
    }

    .image-container:hover .purchase_order_hover,
    .image-container:hover .term_funding_hover {
        opacity: 0.9;
        pointer-events: auto;
    }

    .image-container:hover .purchase_order_hover button,
    .image-container:hover .purchase_order_hover h2,
    .image-container:hover .purchase_order_hover p,
    .image-container:hover .purchase_order_para,
    .image-container:hover .term_funding_para,
    .image-container:hover .merchant_cash_para {
        display: block;
    }

    .products_second_row {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .products_second_row .image-container img {
        width: 100%;
    }
    .purchase_order_button,
    .term_funding_button,
    .loc_button,
    .business_loan_button,
    .merchant_cash_button {
        display: none;
        position: absolute;
        top: 10%;
        left: 10%;
        transform: translate(-10%, -10%);
        background-color: #3AA641;
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        opacity: 0;
        transition: opacity 0.4s ease-out;
    }
    .purchase_order_para {
        display: none;
        position: absolute;
        width: 50%;
        top: 25%;
        left: 9%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }
    .term_funding_para {
        display: none;
        position: absolute;
        width: 80%;
        top: 25%;
        left: 8%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }
    .merchant_cash_para {
        display: none;
        position: absolute;
        width: 60%;
        top: 25%;
        left: 6%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }


    .image-container:hover .purchase_order_button,
    .image-container:hover .business_loan_button,
    .image-container:hover .loc_button,
    .image-container:hover .merchant_cash_button,
    .image-container:hover .term_funding_button {
        display: block;
        left: 10%;
        opacity: 1;
        transform: translate(-10%, -10%);
    }
}








@media (min-width: 501px) and (max-width: 1023px) {

    #product_background_picture {
        display: flex;
        position: relative;
        /* overflow: hidden; */
    }

    #product_background_picture img {
        height: 480px;
        width: 100%;
        object-fit: cover;
    }

    #product_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        left: 0;
        width: 45%;
        height: 20%;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .product_image_heading_master {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product_image_heading {
        display: flex;
        position: absolute;
        color: black;
        top: 60%;
        width: 150px;
        padding: 5px 10px 5px 10px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: 500;
        height: 60px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 80%;
        border-radius: 5px;

    }
    .product_image_heading:hover {
        opacity: 100%;
    }

    .product_service_background {
        display: flex;
        width: 100%;
        background-color: #F2F4F8;
        padding: 0 0 30px 0;
        /* margin-bottom: 40px; */
    }

    .product_service_offered {
        display: flex;
        margin-left: 60px;
        width: 100%;
    }

    .product_service_offered span {
        color: #3AA641;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        transform: rotate(-90deg);
        width: 1%;
        margin-top: 110px;
    }

    .product_service_offered h2 {
        display: flex;
        font-family: "Jost", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
        width: 40%;
        text-transform: capitalize;
        padding: 0 0 0 20px;
    }

    .products_master_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .products_first_row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .image-container {
        position: relative;
    }
    .image-container img {
        display: flex;
        width: 100%;
        height: 450px;
        opacity: 1;
        transition: opacity 0.5s;
    }
    .image-container:hover img {
        opacity: 0.9;
    }

    .purchase_order_hover,
    .term_funding_hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        opacity: 0;
        transition: background-color 0.4s, opacity 0.4s;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .purchase_order_hover button,
    .purchase_order_hover h2,
    .purchase_order_hover p {
        display: none;
    }

    .image-container:hover .purchase_order_hover,
    .image-container:hover .term_funding_hover {
        opacity: 0.9;
        pointer-events: auto;
    }

    .image-container:hover .purchase_order_hover button,
    .image-container:hover .purchase_order_hover h2,
    .image-container:hover .purchase_order_hover p,
    .image-container:hover .purchase_order_para,
    .image-container:hover .term_funding_para,
    .image-container:hover .merchant_cash_para {
        display: block;
    }

    .products_second_row {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }
    .products_second_row .image-container img {
        width: 100%;
    }
    .purchase_order_button,
    .term_funding_button,
    .loc_button,
    .business_loan_button,
    .merchant_cash_button {
        display: none;
        position: absolute;
        top: 8%;
        left: 10%;
        transform: translate(-10%, -10%);
        background-color: #3AA641;
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        opacity: 0;
        transition: opacity 0.4s ease-out;
    }
    .purchase_order_para {
        display: none;
        position: absolute;
        width: 60%;
        top: 25%;
        left: 9%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }
    .term_funding_para {
        display: none;
        position: absolute;
        width: 80%;
        top: 20%;
        left: 8%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 10px 0 20px;
        border-radius: 5px;
    }
    .term_funding_para h2 {
        font-size: 18px;
    }
    .merchant_cash_para {
        display: none;
        position: absolute;
        width: 70%;
        top: 25%;
        left: 6%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }


    .image-container:hover .purchase_order_button,
    .image-container:hover .business_loan_button,
    .image-container:hover .loc_button,
    .image-container:hover .merchant_cash_button,
    .image-container:hover .term_funding_button {
        display: block;
        left: 10%;
        opacity: 1;
        transform: translate(-10%, -10%);
    }
}












@media (max-width: 500px) {

    #product_background_picture {
        display: flex;
        position: relative;
        /* overflow: hidden; */
    }

    #product_background_picture img {
        height: 480px;
        width: 100%;
        object-fit: cover;
    }

    #product_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        left: 0;
        width: 45%;
        height: 20%;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .product_image_heading_master {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product_image_heading {
        display: flex;
        position: absolute;
        color: black;
        top: 45%;
        width: 150px;
        padding: 5px 10px 5px 10px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: 500;
        height: 60px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 80%;
        border-radius: 5px;

    }
    .product_image_heading:hover {
        opacity: 100%;
    }

    .product_service_background {
        display: flex;
        width: 100%;
        background-color: #F2F4F8;
        padding: 0 0 30px 0;
        /* margin-bottom: 40px; */
    }

    .product_service_offered {
        display: flex;
        margin-left: 60px;
        width: 100%;
    }

    .product_service_offered span {
        color: #3AA641;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        transform: rotate(-90deg);
        width: 1%;
        margin-top: 110px;
    }

    .product_service_offered h2 {
        display: flex;
        font-family: "Jost", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
        width: 40%;
        text-transform: capitalize;
        padding: 0 0 0 20px;
    }

    .products_master_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .products_first_row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .image-container {
        position: relative;
    }
    .image-container img {
        display: flex;
        width: 100%;
        height: 450px;
        opacity: 1;
        transition: opacity 0.5s;
    }
    .image-container:hover img {
        opacity: 0.9;
    }

    .purchase_order_hover,
    .term_funding_hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        opacity: 0;
        transition: background-color 0.4s, opacity 0.4s;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .purchase_order_hover button,
    .purchase_order_hover h2,
    .purchase_order_hover p {
        display: none;
    }

    .image-container:hover .purchase_order_hover,
    .image-container:hover .term_funding_hover {
        opacity: 0.9;
        pointer-events: auto;
    }

    .image-container:hover .purchase_order_hover button,
    .image-container:hover .purchase_order_hover h2,
    .image-container:hover .purchase_order_hover p,
    .image-container:hover .purchase_order_para,
    .image-container:hover .term_funding_para,
    .image-container:hover .merchant_cash_para {
        display: block;
    }

    .products_second_row {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }
    .products_second_row .image-container img {
        width: 100%;
    }
    .purchase_order_button,
    .term_funding_button,
    .loc_button,
    .business_loan_button,
    .merchant_cash_button {
        display: none;
        position: absolute;
        top: 8%;
        left: 10%;
        transform: translate(-10%, -10%);
        background-color: #3AA641;
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        opacity: 0;
        transition: opacity 0.4s ease-out;
    }
    .purchase_order_para {
        display: none;
        position: absolute;
        width: 60%;
        top: 25%;
        left: 9%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }
    .term_funding_para {
        display: none;
        position: absolute;
        width: 80%;
        top: 20%;
        left: 8%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 10px 0 20px;
        border-radius: 5px;
    }
    .term_funding_para h2 {
        font-size: 18px;
    }
    .merchant_cash_para {
        display: none;
        position: absolute;
        width: 70%;
        top: 25%;
        left: 6%;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        word-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 10px 30px 0 20px;
        border-radius: 5px;
    }


    .image-container:hover .purchase_order_button,
    .image-container:hover .business_loan_button,
    .image-container:hover .loc_button,
    .image-container:hover .merchant_cash_button,
    .image-container:hover .term_funding_button {
        display: block;
        left: 10%;
        opacity: 1;
        transform: translate(-10%, -10%);
    }
}





