/* CSS for form styling */
.form-container {
    max-width: 800px;
    /* margin: 0 auto; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    padding: 0 0 0 30px;
  }
  .form-group-master {
    display: flex;
    padding: 0 20px 0 0;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .form-group button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .form-group button:hover {
    background-color: #0056b3;
  }
  