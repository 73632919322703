.privacy_master_div {
    display: flex;
    flex-direction: column;
    /* padding: 0 0 0 30px; */
}


/* App.css */

.container {
    text-align: justify;
    margin: 20px;
}

.heading {
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.sub-heading {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
}

.paragraph {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 10px;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.list li {
    margin-bottom: 5px;
}

/* Add more styles as needed */
