.admin-dashboard {
    margin: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.profile_images {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

button {
    padding: 8px 12px;
    cursor: pointer;
}
