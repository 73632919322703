@media (min-width: 1024px) {
    #lender_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    #lender_background_picture img {
        height: 850px;
        width: 100%;
        object-fit: cover;

    }

    #lender_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        /* right: 0; */
        left: 0;
        width: 30%;
        height: 30%;
        align-items: center;
        justify-content: center;
        font-size: 3.2rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .lender_master_div{
        background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        box-sizing: border-box;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }
    .card_master {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        /* padding: 30px; */
        padding: 30px 20px 30px 0;
        align-items: center;
        justify-content: center;
    }
    .lender_single_card {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 30px;
        width: calc(33.3333% - 20px);
        margin-bottom: 20px;
        box-sizing: border-box;
        margin-left: 20px;
        height: 220px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    .lender_single_card:hover {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .lender_single_card span {
        font-size: 16px;
        color: #4A4A4A;
        font-family: "Jost", sans-serif;
    }
    .lender_cards_icons {
        font-size: 30px;
    }
    .lender_cards_icons:hover {
        color: lightgray;
        cursor: pointer;
    }
}








@media (min-width: 501px) and (max-width: 1023px) {
    #lender_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    #lender_background_picture img {
        height: 850px;
        width: 100%;
        object-fit: cover;

    }

    #lender_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        /* right: 0; */
        left: 0;
        width: 45%;
        height: 25%;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .lender_master_div{
        background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        box-sizing: border-box;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }
    .card_master {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        /* padding: 30px; */
        padding: 30px 20px 30px 0;
        align-items: center;
        justify-content: center;
    }
    .lender_single_card {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 30px;
        width: calc(70% - 20px);
        margin-bottom: 20px;
        box-sizing: border-box;
        margin-left: 20px;
        height: 220px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    .lender_single_card:hover {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .lender_single_card span {
        font-size: 16px;
        color: #4A4A4A;
        font-family: "Jost", sans-serif;
    }
    .lender_cards_icons {
        font-size: 30px;
    }
    .lender_cards_icons:hover {
        color: lightgray;
        cursor: pointer;
    }
}








@media (max-width: 500px) {
    #lender_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    #lender_background_picture img {
        height: 450px;
        width: 100%;
        object-fit: cover;

    }

    #lender_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 50%;
        left: 0;
        width: 35%;
        height: 25%;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .lender_master_div{
        background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        box-sizing: border-box;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }
    .card_master {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding: 30px 20px 30px 0;
        align-items: center;
        justify-content: center;
    }
    .lender_single_card {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 30px;
        width: 70%;
        margin-bottom: 20px;
        box-sizing: border-box;
        margin-left: 20px;
        height: 220px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    .lender_single_card:hover {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .lender_single_card span {
        font-size: 16px;
        color: #4A4A4A;
        font-family: "Jost", sans-serif;
    }
    .lender_cards_icons {
        font-size: 30px;
    }
    .lender_cards_icons:hover {
        color: lightgray;
        cursor: pointer;
    }
}