@media (min-width: 1024px) {
    .input_wrapper {
        padding-left: 20px;
        /* border-bottom: 1px solid #6d7077; */
        width: 80%;
        margin-left: 20px;
        margin-bottom: 10px;

    }
    .lender_form_input {
        background: none;
        border: none;
        padding: 0 ;
        border-bottom: 1px solid #98A1B7;
        display: inline-block;
        margin-bottom: 20px;
        width: 90%;
        height: 30px;
    }
    .lender_submit_div {
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px 10px 0;
    }
    .lender_submit_button {
        background-color: #3AA641;
        border: none;
        border-radius: 5px;
    }
}