@media (min-width: 1024px) {
    #process_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }
    #process_background_picture img {
        height: 460px;
        width: 100%;
        object-fit: cover;
    }

    #process_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        /* right: 0; */
        /* left: 0; */
        /* left: 20%; */
        width: 30%;
        height: 30%;
        /* align-items: center;
        justify-content: center; */
        font-size: 3.2rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }
    .process_image_heading_master {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .process_image_heading {
        display: flex;
        flex-direction: column;
        position: absolute;
        color: black;
        top: 10%;
        width: 250px;
        padding: 15px 20px 15px 20px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 36px;
        font-weight: 500;
        height: 60px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 85%;
        border-radius: 5px;
    
    }
    .process_image_heading:hover {
        opacity: 100%;
    }
    .hand_icon {
        font-size: 28px;
    }
    .application_process {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 10px 50px 0 100px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
    .fill_application_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .fill_application_text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        font-family: "Jost", sans-serif;
    }
    .view_application_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        left: 0;
        font-family: "Jost", sans-serif;
    }
    .fill_application_image{
        display: flex;
        width: 50%;
        /* margin-left: auto; */
        /* left: 0; */
        align-items: center;
        justify-content: center;

    }
    .process_assessment {
        mix-blend-mode:multiply;
    }
    .view_offers {
        mix-blend-mode: multiply;
    }
    .fill_application_image img {
        width: 350px;
        height: 300px;
        margin-right: auto;
        margin-left: 100px;
    }

    .view_offer_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 100px 0 0;
    }
    .view_application_image {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: center;
    }
    .view_application_image img {
        width: 350px;
        height: 300px;
    }
    .lender {
        mix-blend-mode:multiply;
    }
    .accept_offer {
        mix-blend-mode: multiply;
    }
    .agreement_img {
        mix-blend-mode: multiply;
    }
}









@media (min-width: 501px) and (max-width: 1023px) {
    #process_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }
    #process_background_picture img {
        height: 850px;
        width: 100%;
        object-fit: cover;

    }

    #process_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        /* right: 0; */
        left: 0;
        width: 30%;
        height: 30%;
        align-items: center;
        justify-content: center;
        font-size: 3.2rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }

    .process_image_heading_master {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .process_image_heading {
        display: flex;
        flex-direction: column;
        position: absolute;
        color: black;
        top: 10%;
        width: 250px;
        padding: 15px 20px 15px 20px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 36px;
        font-weight: 500;
        height: 60px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 85%;
        border-radius: 5px;
    
    }
    .process_image_heading:hover {
        opacity: 100%;
    }
    .hand_icon {
        font-size: 28px;
    }


    .application_process {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 10px 50px 0 100px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
    .fill_application_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .fill_application_text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        font-family: "Jost", sans-serif;
    }
    .view_application_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        left: 0;
        font-family: "Jost", sans-serif;
    }
    .fill_application_image{
        display: flex;
        width: 50%;
        /* margin-left: auto; */
        /* left: 0; */
        align-items: center;
        justify-content: center;
    }
    .fill_application_image img {
        width: 350px;
        height: 300px;
        margin-right: auto;
        margin-left: 100px;
    }

    .view_offer_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        padding: 0 100px 0 0;
    }
    .view_application_image {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: center;
    }
    .view_application_image img {
        width: 350px;
        height: 300px;
    }
    .lender {
        mix-blend-mode:multiply;
    }
    .accept_offer {
        mix-blend-mode: multiply;
    }
    .agreement_img {
        mix-blend-mode: multiply;
    }
}






@media (max-width: 500px) {
    #process_background_picture {
        display: flex;
        position: relative;
        overflow: hidden;
    }
    #process_background_picture img {
        height: 450px;
        width: 100%;
        object-fit: cover;

    }

    #process_background_picture span {
        font-family: "Rubik", sans-serif;
        display: flex;
        position: absolute;
        top: 25%;
        left: 0;
        width: 45%;
        height: 20%;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        padding: 10px 30px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.5);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        line-height: 1.25;
        animation: lenderslide 2s forwards;

    }

        @keyframes lenderslide {
        0% {
            left: -30%;
        }

        100% {
            left: 0;
        }
    }


    .process_image_heading_master {
        display: flex;
        /* align-items: center; */
        justify-content: center;
    }
    
    .process_image_heading {
        display: flex;
        flex-direction: column;
        position: absolute;
        color: black;
        top: 45%;
        width: 180px;
        padding: 10px 0 10px 0;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 25px;
        font-weight: 500;
        height: 50px;
        background-color: #3AA641;
        cursor: pointer;
        opacity: 85%;
        border-radius: 5px;
    
    }
    .process_image_heading:hover {
        opacity: 100%;
    }
    .hand_icon {
        font-size: 20px;
    }
    
    .process_master_div {
        background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        box-sizing: border-box;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }
    .application_process {
        position: relative;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 10px 50px 0 10px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
    .fill_application_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .fill_application_text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        font-family: "Jost", sans-serif;
    }
    .view_application_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        left: 0;
        font-family: "Jost", sans-serif;
    }
    .fill_application_image{
        display: flex;
        width: 30%;
        align-items: center;
        justify-content: center;
    }
    .fill_application_image img {
        width: 200px;
        height: 300px;
        margin-right: auto;
        margin-left: 100px;
    }

    .view_offer_external {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 20px;
    }
    .view_application_image {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
    }
    .view_application_image img {
        width: 200px;
        height: 220px;
    }
    .lender {
        mix-blend-mode:multiply;
    }
    .accept_offer {
        mix-blend-mode: multiply;
    }
    .agreement_img {
        mix-blend-mode: multiply;
    }
}