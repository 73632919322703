@media (min-width: 1024px) {
    .navbar_master_div {
        display: block !important;
        top: 7.5vh;
        left: 0;
        margin: 0;
        width: 100%;
        flex-wrap: nowrap;
        height: 12vh;
        background-color: transparent;
        box-sizing: border-box;
        position: absolute;
    }

    .navbar_scrolled {
        background-color: #000;
        position: fixed;
        top: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 5;
        -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .navbar_log {
        display: flex;
        margin-top: 5px;
        padding: 0 200px 0 120px;
        height: 10vh;
    }

    .navbar_component {
        display: flex;
        height: 100%;
        width: 100%;
    }

    .navbar_buttons {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: 80px;
        /* gap: 25px; */
        color: #2f3d44;
    }

    .navbar_button {
        display: flex;
        /* display: inline-block; */
        text-decoration: none;
        color: #333;
        position: relative;
        cursor: pointer;
        padding-right: 8px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin-left: 30px;
        height: 55px;
        color: #fff;
        height: 100%;
    }

    .navbar_button::before,
    .navbar_button::after {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 50%;
        width: 0;
        height: 2.5px;
        background-color: #fff;
        color: #fff;
        transition: width 0.3s ease, left 0.3s ease;
        transform: translateX(-50%);

    }

    .navbar_button:hover::before,
    .navbar_button:hover::after {
        color: #fff;
        width: 90%;
    }

    .navbar_button.highlighted {
        color: #fff;
        background-color: #3AA641;
        display: flex;
        border-radius: 2px;
        padding-left: 8px;
    }

    .navbar_button.highlighted::before,
    .navbar_button.highlighted::after {
        top: 0;
    }

    .navbar_dropdown {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-weight: 500;
    }

    .dropdown_menu {
        display: none;
        position: absolute;
        width: 200px;
        top: 20px;
        left: 0;
        background-color: #fff;
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1;
    }

    .navbar_dropdown:hover .dropdown_menu {
        display: block;

    }

    .dropdown_menu li {
        padding: 10px 20px;
        /* color: #2f3d44; */
        color: #fff;
        cursor: pointer;
    }

    .dropdown_menu li:hover {
        background-color: #3AA641;
        border-radius: 5px;
        color: #2f3d44;
    }

    .navbar_home_icon {
        display: flex;
        margin: 0px 5px 0 0;
    }

    .apply_button_container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 20px;
        background-color: #3AA641;
        color: #fff;
        width: 120px;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        margin-left: 20px;
    }

    .search_icon {
        display: flex;
        font-size: 22px;
        color: #fff;
        cursor: pointer;
        margin-left: 20px;
    }

    .navbar_process_icon {
        display: flex;
        margin: 0px 5px 0 0;
    }

    .menu_button {
        display: none;
    }


    /* .navbar_buttons .black_background {
        background-color: black;
    } */
    
    .navbar_dashboard {
        background-color: black;
    }


    .dropdown_menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #3AA641;
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        display: none;
        width: 220px;
    }
    
    .dropdown_menu.active {
        display: block; /* Show the dropdown menu when active */
        color: #fff;
    }
    .dropdown_title {
        font-weight: bold;
        color: #fff;
        text-decoration-color: #fff;
    }
}









@media (min-width: 501px) and (max-width: 1023px) {
    .navbar_master_div {
        display: block !important;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 10vh;
        background-color: transparent;
        box-sizing: border-box;
        position: absolute;
    }

    .navbar_scrolled {
        background-color: #000;
        position: fixed;
        width: 100%;
        top: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        /* -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 300ms ease-in-out 0s normal none 1 running fadeInDown; */
    }

    /* @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translateY(-15px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    } */

    .navbar_log {
        display: flex;
        /* width: 10%; */
        height: 8vh;
        top: 0;
        padding: 10px 20px 0 20px;

    }
    .navbar_log img {
        margin-bottom: 10px;
        /* width: 90px; */
        height: 38px;
    }
    .navbar_component {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .navbar_button,
    .apply_button_container,
    .navbar_dropdown,
    .search_icon {
        display: none;
    }

    .navbar_buttons {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #3AA641;
        padding: 15px 0 15px 0;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
    }

    .menu_button {
        display: flex;
        background-color: #2f3d44;
        border: none;
        color: #fff;
        padding: 0.438em 0.625em;
        font-size: 16px;
        font-family: "Jost", sans-serif;
        font-weight: 700;
        text-shadow: 0 1px 3px #000;
        cursor: pointer;
        border-radius: 5px;
        line-height: 1.125em;
        margin-right: 50px;
    }

    .menu_icon {
        display: flex;
        margin-left: 4px;
        font-size: 16px;
        color: #fff;
    }

    .dropdown_menu {
        display: flex;
        width: 100%;
        flex-direction: column;
        left: 0;
        position: relative;
        background-color: #3AA641;
        z-index: 1;
        -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .dropdown_menu ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

    }

    .dropdown_menu li {
        padding: 10px 20px;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-family: "Jost", sans-serif;
        font-size: 14px;
    }

    .dropdown_menu li:hover {
        background-color: #3AA641;
    }

}










@media (max-width: 500px) {
    .navbar_master_div {
        display: block !important;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 10vh;
        background-color: transparent;
        box-sizing: border-box;
        position: absolute;
    }

    .navbar_scrolled {
        background-color: #000;
        position: fixed;
        width: 100%;
        top: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        margin-right: 30px;
        /* -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 300ms ease-in-out 0s normal none 1 running fadeInDown; */
    }

    /* @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translateY(-15px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    } */

    .navbar_scrolled .menu_button {
        margin-right: 30px;
    }
    .navbar_log {
        display: flex;
        width: 100px;
        height: 8vh;
        top: 0;
        padding: 10px 20px 0 20px;
    }
    .navbar_component {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
    .navbar_log img {
        width: 70px;
        margin-bottom: 10px;
    }
    .navbar_button,
    .apply_button_container,
    .navbar_dropdown,
    .search_icon {
        display: none;
    }

    .navbar_buttons {
        display: flex;
        width: 100%;
        background-color: #3AA641;
        padding: 15px 15px 15px 0;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
    }

    .menu_button {
        display: flex;
        background-color: #2f3d44;
        border: none;
        color: #fff;
        padding: 0.438em 0.625em;
        font-size: 16px;
        font-family: "Jost", sans-serif;
        font-weight: 700;
        text-shadow: 0 1px 3px #000;
        cursor: pointer;
        border-radius: 5px;
        line-height: 1.125em;
        margin-right: 30px;
    }

    .menu_icon {
        display: flex;
        margin-left: 4px;
        font-size: 16px;
        color: #fff;
    }

    .dropdown_menu {
        display: flex;
        width: 100%;
        flex-direction: column;
        left: 0;
        position: relative;
        background-color: #3AA641;
        z-index: 1;
        -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .dropdown_menu ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

    }

    .dropdown_menu li {
        padding: 10px 20px;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-family: "Jost", sans-serif;
        font-size: 14px;
    }

    .dropdown_menu li:hover {
        background-color: #3AA641;
    }
}
